import type { Stempelserie, Stempelstelle } from "./types";

const apiUrl = import.meta.env.VITE_BACKEND_URL + '/api.php';

class _StempelstellenService {
    private _auth: string | null = null;

    constructor() {
        const savedAuth = localStorage.auth;
        if(savedAuth != null) {
            this._auth = savedAuth;
        }
    }

    public get isAuthenticated() {
        return this._auth != null;
    }

    private getHeaders(auth?: string) {
        return {
            'Authentication': auth ?? this._auth ?? '',
        };
    }

    public async setAuth(password: string): Promise<boolean> {
        const response = await fetch(`${apiUrl}?check-auth`, {
            headers: this.getHeaders(btoa(password)),
        });
        if(response.ok) {
            localStorage.auth = this._auth = btoa(password);
        }

        return response.ok;
    }

    public async updateStempelVisited(id: number, visited: boolean): Promise<void> {
        const response = await fetch(`${apiUrl}?update&stempel=${id}&visited=${visited}`, {
            headers: this.getHeaders(),
        });
    }
    
    public async getAll(): Promise<{
        stempel: Record<number, Stempelstelle>;
        stempelserien: Record<number, Stempelserie>;
    }> {
        return await fetch(apiUrl + '?get', {
            headers: this.getHeaders(),
        }).then(r => r.json());
    }
    
    public async ping() {
        await fetch(apiUrl + '?ping', {
            headers: this.getHeaders(),
        });
    }
}

export const StempelstellenService = new _StempelstellenService();