import mapbox, { LngLat, LngLatBounds, Map, NavigationControl, ScaleControl } from 'mapbox-gl';

mapbox.accessToken = 'pk.eyJ1IjoianZvcnJlaXRlciIsImEiOiJjbGVyMG03Z3AwMmZxNDNtcHVvYmZsZDQ3In0.ztS_H8Ce6g573L4ELzIY1w';

const boundsNorthEast = new LngLat(11.65, 52);
const boundsSouthWest = new LngLat(10, 51.3);
const center = new LngLat(
    (boundsSouthWest.lng + boundsNorthEast.lng) / 2,
    (boundsSouthWest.lat + boundsNorthEast.lat) / 2,
);
export const map = new Map({
    container: 'map',
    projection: { name: 'naturalEarth' },
    style: 'mapbox://styles/jvorreiter/cler1e5v8000701mtbhpqxpvr',
    center: center,
    zoom: 11,
    minZoom: 6,
    maxZoom: 16,
    maxBounds: new LngLatBounds(boundsSouthWest, boundsNorthEast),
});
map.addControl(
    new NavigationControl({
        visualizePitch: true,
    })
);
map.addControl(
    new ScaleControl({ unit: 'metric' })
);