import mapbox from "mapbox-gl";

export type Route = {
    coordinates: [longitude: number, latitude: number][];
    distanceInMeters: number;
    durationInSeconds: number;
}

class _MapboxNavigationService {
    private async getOptimalRoute(coordinates: [longitude: number, latitude: number][]): Promise<[longitude: number, latitude: number][] | null> {
        const directionProfile = 'mapbox/walking';
        const directionCoordinates = coordinates.map<string>(c => `${c[0]},${c[1]}`).join(';');
        const params = new URLSearchParams({
            roundtrip: 'true',
            access_token: mapbox.accessToken,
        });
        const url = `https://api.mapbox.com/optimized-trips/v1/${directionProfile}/${directionCoordinates}?${params}`;

        const response = await fetch(url);
        if (!response.ok) {
            return null;
        }

        const json = await response.json();
        const waypoints = json.waypoints.sort((a: any, b: any) => a.waypoint_index - b.waypoint_index);
        return waypoints.map((w: any) => w.location);
    }

    public async getDirections(coordinates: [longitude: number, latitude: number][]): Promise<Route | null> {
        if (coordinates.length > 12 || coordinates.length < 2) {
            return null;
        }

        const optimalCoordinates = await this.getOptimalRoute(coordinates);
        if(optimalCoordinates == null) {
            return null;
        }
        coordinates = optimalCoordinates;
        coordinates.push(coordinates[0]);

        const directionProfile = 'mapbox/walking';
        const directionCoordinates = coordinates.map<string>(c => `${c[0]},${c[1]}`).join(';');
        const params = new URLSearchParams({
            overview: 'simplified',
            geometries: 'geojson',
            language: 'en',
            alternatives: 'false',
            steps: 'true',
            access_token: mapbox.accessToken,
        });
        const url = `https://api.mapbox.com/directions/v5/${directionProfile}/${directionCoordinates}?${params}`;

        const response = await fetch(url);
        if (!response.ok) {
            return null;
        }

        const json = await response.json();
        const route = json.routes[0];
        return {
            durationInSeconds: route.duration,
            distanceInMeters: route.distance,
            coordinates: route.geometry.coordinates,
        };
    }
}

export const MapboxNavigationService = new _MapboxNavigationService();