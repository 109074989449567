<script setup lang="ts">
import { useHarzerWandernadel, type SerienConfig } from "./store";
import { StempelstellenService } from './services/StempelstellenService';
import './styles/map.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Marker, Popup } from 'mapbox-gl';
import { onMounted, reactive, ref, watch } from "vue";
import { map } from './map';
import type { Stempelserie, Stempelstelle } from "./services/types";
import { MapboxNavigationService, type Route } from "./services/MapboxNavigationService";
import { $mapEvents } from "./map-events";

type Mode = 'stamps' | 'route';
const mode = ref<Mode>('stamps');

const store = useHarzerWandernadel();

const stempelPopupElement = ref<HTMLDivElement | null>(null);
const stempelPopup = new Popup();
const showNav = ref(false);

watch(
    () => store.activeStempel,
    (stempel) => {
        if (stempel == null) {
            return;
        }

        stempelPopup.setDOMContent(stempelPopupElement.value!);
        stempelPopup
            .remove()
            .setLngLat({
                lat: stempel.latitude,
                lng: stempel.longitude,
            })
            .setDOMContent(stempelPopupElement.value!)
            .addTo(map);
    }
);

const stempellistenVisibility: Record<number, { [Key in 'visited' | 'notVisited']: boolean | undefined }> = reactive({});

onMounted(() => {
    map.on('load', async () => {
        await authenticate();

        await store.load(map);
        map.resize();
    });
});

const markerColors: string[] = [
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#03A9F4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFEB3B',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548',
    '#607D8B',
    '#757575',
    '#FF1744',
    '#D500F9',
    '#01579B',
    '#C6FF00',
    '#FFD600',
    '#8D6E63',
];
const waypoints = reactive<{
    coordinates: {
        lng: number,
        lat: number
    },
    marker: Marker,
    color: string,
}[]>([]);
const route = ref<Route | null>(null);
map.on('click', e => {
    if (mode.value != 'route') {
        return;
    }

    const color = markerColors[waypoints.length];
    const marker = new Marker({
        scale: 0.65,
        color,
    }).setLngLat(e.lngLat)
        .addTo(map);

    waypoints.push({
        coordinates: e.lngLat,
        marker,
        color,
    });
});

let hadRoute = false;
async function getRoute() {
    const coords = waypoints.map<[lng: number, lat: number]>(w => [w.coordinates.lng, w.coordinates.lat]);
    route.value = await MapboxNavigationService.getDirections(coords);

    if (route.value == null) {
        return;
    }

    if (hadRoute) {
        map.removeLayer('route');
        map.removeSource('route');
    }

    map.addSource('route', {
        type: 'geojson',
        data: {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: route.value.coordinates,
            },
        },
    });
    map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
            'line-cap': 'round',
            'line-join': 'round',
        },
        paint: {
            'line-color': '#F50057',
            'line-width': 8,
        },
    });

    hadRoute = true;
}

function clearRoute() {
    waypoints.forEach(w => w.marker.remove());
    waypoints.splice(0);

    route.value = null;
}

async function authenticate() {
    if (StempelstellenService.isAuthenticated) {
        return;
    }

    do {
        const password = window.prompt('login') ?? '';
        const success = await StempelstellenService.setAuth(password);
        if (success) {
            break;
        }
    } while (true);
}

function toggleMapLayerVisibility(serieId: number, isVisitedLayer: boolean) {
    const config = store.serienConfig[serieId];
    if (config == null) {
        throw new Error(`Serie "${serieId}" nicht gefunden.`);
    }

    const key = isVisitedLayer ? 'visited' : 'notVisited';
    const visible = (config[key].show = !config[key].show);
    map.setLayoutProperty(config[key].layerId, 'visibility', visible ? 'visible' : 'none');
}

function showHideStampList(serie: Stempelserie, visited: boolean) {
    stempellistenVisibility[serie.id] ??= {
        visited: false,
        notVisited: false,
    };

    const visible = stempellistenVisibility[serie.id][visited ? 'visited' : 'notVisited'];
    stempellistenVisibility[serie.id][visited ? 'visited' : 'notVisited'] = !visible;
}

function focusStempel(stempel: Stempelstelle) {
    map.flyTo({
        center: [stempel.longitude, stempel.latitude],
    });
}


const gpsMarkerDiv = document.createElement('div');
gpsMarkerDiv.classList.add('gps-marker');
const gpsMarker = new Marker({
    element: gpsMarkerDiv,
}).setLngLat([0, 0]).addTo(map);
navigator.geolocation.getCurrentPosition(pos => {
    gpsMarker.setLngLat({
        lng: pos.coords.longitude,
        lat: pos.coords.latitude,
    });
}, null, {
    enableHighAccuracy: true,
});
navigator.geolocation.watchPosition(pos => {
    console.log(pos);
    gpsMarker.setLngLat({
        lng: pos.coords.longitude,
        lat: pos.coords.latitude,
    });
}, null, {
    enableHighAccuracy: true,
});
</script>

<template>
    <div ref="stempelPopupElement">
        <template v-if="store.activeStempel?.serienIds != null">
            <div v-text="store.activeStempel.name" />
            <div>
                <img
                    v-for="serieId in store.activeStempel.serienIds"
                    :src="`/img/icons/${store.serienById[serieId].icon}.svg`"
                    class="serie-icon"
                />
            </div>
            <label style="display: flex; align-items: center;">
                <input
                    type="checkbox"
                    :checked="store.activeStempel.visited"
                    @input="store.updateStempelVisited(map, store.activeStempel!.id, !store.activeStempel!.visited)"
                />
                <span v-text="'besucht'" />
            </label>
        </template>
    </div>

    <div
        class="nav-overlay"
        :style="{
            display: showNav ? undefined : 'none',
        }"
        @click="showNav = false"
    />
    <div
        class="nav-toggler"
        v-if="!showNav"
        v-text="'Menu'"
        @click="showNav = true"
    />
    <div
        class="nav"
        :class="{
            'nav--open': showNav,
        }"
    >
        <div class="tabs">
            <div
                class="tab"
                :class="{ active: mode == 'stamps' }"
                v-text="'Stempel'"
                @click="mode = 'stamps'"
            />
            <div
                class="tab"
                :class="{ active: mode == 'route' }"
                v-text="'Route'"
                @click="mode = 'route'"
            />
        </div>
        <template v-if="mode == 'stamps'">
            <div
                v-for="config in (Object.values(store.serienConfig) as SerienConfig[])"
                class="serie-config"
            >
                <div class="serie-header">
                    <img
                        :src="`/img/icons/${config.serie.icon}.svg`"
                        class="serie-icon"
                    />
                    <span v-text="config.serie.benennung" />
                </div>

                <div class="serie-toggle-layers">
                    <template v-for="v in [{
                        visited: false,
                        key: 'notVisited',
                        label: 'nicht besucht',
                        stempel: store.stempel.filter(s => s.serienIds.includes(config.serie.id) && !s.visited),
                    }, {
                        visited: true,
                        key: 'visited',
                        label: 'besucht',
                        stempel: store.stempel.filter(s => s.serienIds.includes(config.serie.id) && s.visited),
                    }] as const">
                        <div>
                            <div style="display: flex; align-items: center;">
                                <button
                                    class="toggle-stempel-liste"
                                    v-text="stempellistenVisibility[config.serie.id]?.[v.key] ? '-' : '+'"
                                    @click="showHideStampList(config.serie, v.visited)"
                                />
                                <label>
                                    <input
                                        type="checkbox"
                                        :checked="config[v.key].show"
                                        @input="toggleMapLayerVisibility(config.serie.id, v.visited)"
                                    />
                                    <span v-text="`${v.label} (${v.stempel.length})`" />
                                </label>
                            </div>
                            <div
                                v-if="stempellistenVisibility[config.serie.id]?.[v.key]"
                                class="stempel-list"
                            >
                                <div
                                    v-for="stempel in v.stempel"
                                    class="stempel-list-item"
                                >
                                    <button
                                        class="toggle-stempel-liste"
                                        @click="focusStempel(stempel)"
                                    >
                                        <div
                                            v-text="'⌖'"
                                            style="transform: scale(2); transform-origin: center;"
                                        />
                                    </button>
                                    <span v-text="stempel.name" />
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <template v-else-if="mode == 'route'">
            <div
                v-if="waypoints.length == 0"
                v-text="'Auf die Karte klicken, um Wegpunkte hinzuzufügen.'"
            />
            <div v-else>
                <button
                    v-text="'Route berechnen'"
                    @click="getRoute()"
                />
                <button
                    v-text="'Wegpunkte entfernen'"
                    @click="clearRoute()"
                />
            </div>

            <div v-if="route != null">
                <div v-text="`Länge: ${Math.ceil(route.distanceInMeters) / 1000}km`" />
            </div>

            <div class="waypoints">
                <template v-for="waypoint in waypoints">
                    <div
                        :style="{ 'background-color': waypoint.color }"
                        class="waypoint-color"
                    />
                    <div
                        v-text="waypoint.coordinates.lng"
                        class="waypoint-coords"
                    />
                    <div
                        v-text="waypoint.coordinates.lat"
                        class="waypoint-coords"
                    />
                </template>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
.gps-marker {
    position: relative;
    width: 64px;
    height: 64px;
    background: rgba(0, 110, 255, 0.2);
    border: 1px solid rgb(0, 110, 255, 0.5);
    border-radius: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        background: rgb(0, 110, 255);
        border-radius: 100%;
    }
}
</style>

<style lang="scss" scoped>
.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 350px;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    background: white;

    padding: 12px 16px;
    box-sizing: border-box;
    transition: transform 100ms;
    box-shadow: -1px 0 12px rgba(black, 0.5);

    &-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(4px);
        background: rgba(0, 0, 0, 0.33);
    }

    &-toggler {
        display: none;
        position: fixed;
        top: 16px;
        left: 16px;
        background: white;
        padding: 4px;
        border-radius: 4px;
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
}

@media screen and (max-width: 1111px) {
    .nav {
        transform: translateX(-100%) translateX(-16px);

        &--open {
            transform: translateX(0);
        }

        &-toggler {
            display: block;
        }
    }
}

.serie-config {
    border: 1px solid rgb(0, 0, 0, 0.3);
    padding: 6px;
    border-radius: 8px;

    &+& {
        margin-top: 16px;
    }
}

.serie-header {
    display: flex;
    align-items: center;
    gap: 4px;
}

.serie-icon {
    width: 20px;
}

.serie-toggle-layers {
    margin-top: 4px;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
}

.toggle-stempel-liste {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: none;
    box-shadow: 1px 1px 2px rgba(black, 0.33);
    margin-right: 8px;
    cursor: pointer;
}

.stempel-list {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-item {
        margin-left: 32px;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
    }
}

.tabs {
    display: flex;
    margin-bottom: 8px;

    .tab {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        background: rgba(black, 0.15);
        cursor: pointer;
        font-weight: bold;
        padding: 4px;

        &:first-of-type {
            border-radius: 6px 0 0 6px;
        }

        &:last-of-type {
            border-radius: 0 6px 6px 0;
        }

        &.active {
            background: rgb(58, 183, 255);
            color: white;
        }
    }
}

.waypoints {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    gap: 4px;
    align-items: center;
}

.waypoint-color {
    width: 16px;
    height: 16px;
    border-radius: 8px;
}

.waypoint-coords {
    font-size: 0.85rem;
}
</style>