import { defineStore } from "pinia";
import { StempelstellenService } from "./services/StempelstellenService";
import type { Stempelserie, Stempelstelle } from "./services/types";
import type { Feature, Geometry, GeoJsonProperties, Point } from 'geojson';
import type { GeoJSONSource, Map } from "mapbox-gl";
import { $mapEvents } from "./map-events";

export type SerienConfig = {
    [Key in 'visited' | 'notVisited']: {
        layerId: string;
        show: boolean;
        features: {
            type: 'FeatureCollection';
            features: Feature<Geometry, GeoJsonProperties>[];
        }
    };
} & {
    serie: Stempelserie;
};

type StempelGroup = {
    isVisited: boolean;
    stempel: Stempelstelle[];
    opacity: number;
}

export const useHarzerWandernadel = defineStore('harz', {
    state: () => ({
        stempelserien: <Stempelserie[]>[],
        stempel: <Stempelstelle[]>[],
        serienConfig: <Record<number, SerienConfig>>{},
        activeStempel: <Stempelstelle | null>null,
        authenticated: false,
    }),
    getters: {
        serienById: (state): Record<number, Stempelserie> => {
            const result: Record<number, Stempelserie> = {};
            state.stempelserien.forEach(serie => result[serie.id] = serie);
            return result;
        },
        stempelById: (state): Record<number, Stempelstelle> => {
            const result: Record<number, Stempelstelle> = {};
            state.stempel.forEach(serie => result[serie.id] = serie);
            return result;
        },
    },
    actions: {
        async updateStempelVisited(map: Map, id: number, visited: boolean) {
            await StempelstellenService.updateStempelVisited(id, visited);

            const stempel = this.stempelById[id];
            const oldVisited = stempel.visited;
            stempel.visited = visited;

            const updateStempelserien = stempel.serienIds.map(id => this.serienById[id]);
            updateStempelserien.forEach(serie => {
                const oldConfigKey = oldVisited ? 'visited' : 'notVisited';
                const oldSourceKey = `${serie.id}${oldVisited ? '--visited' : ''}`;
                const oldData = this.serienConfig[serie.id][oldConfigKey].features;
                const oldFeatures = oldData.features;
                const oldIndex = oldFeatures.findIndex(f => (f.properties as Stempelstelle).id == id);
                if (oldIndex == -1) {
                    throw new Error('wat');
                }
                const feature = oldFeatures.splice(oldIndex, 1)[0];
                (map.getSource(oldSourceKey) as GeoJSONSource).setData(oldData);

                const configKey = visited ? 'visited' : 'notVisited';
                const sourceKey = `${serie.id}${visited ? '--visited' : ''}`;
                const data = this.serienConfig[serie.id][configKey].features;
                data.features.push(feature);
                (map.getSource(sourceKey) as GeoJSONSource).setData(data);
            });
        },
        async load(map: Map) {
            setInterval(() => StempelstellenService.ping(), 60 * 1_000);

            await this.loadData();

            const stempelserien = [...this.stempelserien].sort((a, b) => a.id == 1 ? 1 : b.id == 1 ? -1 : 0);
            for (const stempelserie of stempelserien) {
                const stempel = this.stempel.filter(s => s.serienIds.includes(stempelserie.id));
                const besuchteStempel = stempel.filter(s => s.visited);
                const unbesuchteStempel = stempel.filter(s => !s.visited);

                const groups: StempelGroup[] = [
                    {
                        isVisited: true,
                        stempel: besuchteStempel,
                        opacity: 0.5,
                    },
                    {
                        isVisited: false,
                        stempel: unbesuchteStempel,
                        opacity: 1,
                    },
                ];
                for (const group of groups) {
                    const { stempel, opacity, isVisited } = group;

                    const sourceKey = `${stempelserie.id}${isVisited ? '--visited' : ''}`;

                    const img = await new Promise<HTMLImageElement>(resolve => {
                        const img = new Image();
                        img.src = `./img/icons/${stempelserie.icon}${isVisited ? '--visited' : ''}.svg`;

                        img.addEventListener('load', () => resolve(img));
                    });

                    map.addImage(`icon-${sourceKey}`, img);

                    const configKey = isVisited ? 'visited' : 'notVisited';
                    const features: SerienConfig['visited']['features'] = {
                        type: 'FeatureCollection',
                        features: stempel.map<Feature<Geometry, GeoJsonProperties>>(stempel => ({
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: [stempel.longitude, stempel.latitude],
                            },
                            properties: stempel,
                        })),
                    };
                    this.serienConfig[stempelserie.id]![configKey].features = features;

                    map.addSource(sourceKey, {
                        type: 'geojson',
                        data: features,
                    });

                    const layerId = `icons-${sourceKey}`;

                    this.serienConfig[stempelserie.id]![configKey].layerId = layerId;

                    map.addLayer({
                        id: layerId,
                        type: 'symbol',
                        source: sourceKey,
                        layout: {
                            'icon-image': `icon-${sourceKey}`,
                            "icon-allow-overlap": true,
                            'text-allow-overlap': true,
                            'text-field': '{shortName}',
                            'text-size': 12,
                            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                        },
                        paint: {
                            'text-color': 'white',
                            'icon-opacity': opacity,
                            'text-halo-color': 'rgba(0, 0, 0, 1)',
                            'text-halo-width': 1.2,
                            'text-opacity': opacity,
                        },
                        interactive: true,
                    });

                    map.on('click', layerId, e => {
                        const feature = e.features![0];
                        const properties = feature.properties as Stempelstelle;
                        this.activeStempel = this.stempelById[properties.id];

                        $mapEvents.current = e;
                    });
                    map.on('mouseenter', layerId, () => map.getCanvas().style.cursor = 'pointer');
                    map.on('mouseleave', layerId, () => map.getCanvas().style.cursor = '');
                }
            }
        },
        async loadData() {
            const apiData = await StempelstellenService.getAll();
            this.stempelserien = Object.values(apiData.stempelserien);
            this.stempel = Object.values(apiData.stempel);

            this.serienConfig = {};
            this.stempelserien.forEach(serie => {
                this.serienConfig[serie.id] = {
                    serie,
                    visited: {
                        layerId: '',
                        show: true,
                        features: {
                            type: 'FeatureCollection',
                            features: [],
                        },
                    },
                    notVisited: {
                        layerId: '',
                        show: true,
                        features: {
                            type: 'FeatureCollection',
                            features: [],
                        },
                    },
                };
            });
        }
    },
});